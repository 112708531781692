import * as React from "react"
import { ThemeProvider } from "./src/contexts/ThemeContext"
import { CartProvider } from '~/contexts/CartContext'
import { AccountProvider } from '~/contexts/AccountContext'
import { checkMobile } from '~/utils/helpers'


export const onClientEntry = () => {
  const isMobile = checkMobile()
  const el = document.querySelector("html")
  el.setAttribute('hg', localStorage.getItem('homeg') ? localStorage.getItem('homeg') : 'both')
  el.setAttribute('is-m', isMobile.mobile ? 'true' : 'false')
  isMobile.touches ? el.setAttribute('is-t','true') : el.removeAttribute('is-t')

  if (window.location.pathname === '/') {
    setTimeout(() => {document.querySelectorAll('[g]').forEach(x => x.classList.remove('off'))}, 100)
  }
}


export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    {element}
  </ThemeProvider>
)

export const wrapPageElement = ({ element }) => {
  return (
    <CartProvider>
      <AccountProvider>
        {element}
      </AccountProvider>
    </CartProvider>
  )
}