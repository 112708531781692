import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTheme }  from '~/contexts/ThemeContext'
import useCartHook from '~/hooks/useCartHook'

const CartContext = createContext()

export function CartProvider({ children }) {
  const { customer } = useTheme()

  const [didLoad, setDidLoad] = useState(false)
  const [cart, setCart] = useState(null)
  const [cartClose, setCartClose] = useState(false)
  const [cartOpen, setCartOpen] = useState(false)
  const [cartChanging, setCartChanging] = useState(false)
  const [cartError, setCartError] = useState(false)
  const [cartRecs, setCartRecs] = useState([])
  const [showGiftCard, setShowGiftCard] = useState(false)
  const [giftInfo, setGiftInfo] = useState(false)

  const cartApi = useCartHook({
    cart, setCart, cartOpen, setCartClose, setCartOpen, setCartChanging, setCartError, 
    cartRecs, setCartRecs
  })
  const { checkCart, checkDiscount, checkCartParams, checkRecs } = cartApi

  useEffect(() => {
    if (didLoad) return
    setDidLoad(true)
    checkCart()
    checkDiscount()
    checkCartParams()
    checkRecs()
    
  }, [])

  useEffect(() => {
    if (!cartOpen) setCartClose(false)
  }, [cartOpen])

  return (
    <CartContext.Provider value={{ 
      cartApi, cart, setCart,
      cartClose, setCartClose,
      cartOpen, setCartOpen,
      cartChanging, setCartChanging,
      cartError, setCartError,
      cartRecs, setCartRecs,
      showGiftCard, setShowGiftCard,
      giftInfo, setGiftInfo
    }}>
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error('useCart must be used within an CartProvider')
  }
  return context
}

