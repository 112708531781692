import { compact } from '~/utils/helpers'

const PdpInit = () => {
  
  const setupProduct = (product) => {
    product.is_gift = product.handle.includes('gift')
    const color_type = product.handle.includes('venice-wash') ? 'venice_wash' : 'standard'
    let color_obj = {}
    let pline_colors
    if (!product.is_gift && product.pl_pima !== 99998) {
      pline_colors = product?.product_line?.colors || product?.pl_colors
      color_obj = pline_colors[color_type].find(x => x.shop_id === product.shop_id) || color_obj
    }
    let prod_callouts = product.callouts ? product.callouts : []
    const parse_calls = prod_callouts.length > 0 && prod_callouts[0].hasOwnProperty('data')
    if (parse_calls) prod_callouts = prod_callouts.map(x => x.data)
    product.callouts = prod_callouts
    product.category = product?.product_line?.category || product.category
    product.color = color_obj
    product.gender = product?.product_line?.gender || product.gender
    product.pl_colors = pline_colors
    product.pl_handle = product?.product_line?.handle || product.pl_handle
    product.pl_name = product?.product_line?.name || product.pl_name
    product.short_description = product.short_description || product.product_line.short_description
    product.sizing = product.sizing ? product.sizing : product.product_line.sizing
    product.variants = product.variants || []
    product.variants.forEach(x => x['sku'] = `${product.sku}${x.title}`)
    product.outfits = product.outfits || []
    product.outfits = product.outfits.filter(x => !!x)
    product.outfills = product.outfills ? product.outfills : []

    product.is_single = product.variants.length === 1
    product.is_lastc = product.callouts && product.callouts.find(x => x.key === 'last-chance')

    product.recommends = compact(product?.recommends || [])

    const is_csoon = product.csoon || (product.tags && product.tags.includes('callout--comingsoon')) 
    product.csoon = is_csoon
    product.final_sale = product.tags && (product.is_lastc || product.tags.includes('finalsale') )
    product.is_preorder = product.tags && product.tags.includes('preorder')

    if (!product.is_gift && product.pl_pima !== 99998) {
      let combined_filters = product?.product_line?.filters || product?.filters
      Object.keys(product.filters).forEach(x => {
        if (combined_filters.hasOwnProperty(x)) {
          combined_filters[x] = [...new Set(combined_filters[x].concat(product.filters[x]))]
        } else {
          combined_filters[x] = product.filters[x]
        }
      })
      product.filters = combined_filters
    } else {
      product.filters = product.filters || {}
      product.filters['gender'] = ['unisex']
    }

    return product
  }

  const formatVarTitles = (variants, is_gift=false) => {
    let new_vars = structuredClone(variants)
    const test_title = new_vars[0].title.replace('$','').replace(',','')
    const is_num = !isNaN(test_title) && !isNaN(parseFloat(test_title))
    if (is_num) {
      new_vars.forEach(x => x.title = parseFloat(x.title.replace('$','').replace(',','')))
      new_vars.sort((a, b) => a.title - b.title)
      new_vars.forEach(x => x.title = is_gift ? `$${x.title}` : x.title.toString())
    }
    return new_vars
  }

  const whichCntry = (locate) => {
    if (!locate) return 'US'
    return !!locate?.usrCountry ? locate.usrCountry : locate.country
  }

  const buildLocate = {country: 'US', shipIntl: false}

  const setupVariants = ({variants, isGift, prodInv={}, isSHM=false, locate=buildLocate}) => {
    if (isGift) {
      variants.forEach(x => {
        x.ship = true
        x.pickup = false
      })
      return formatVarTitles(variants, isGift)
    }

    const new_vars = structuredClone(variants)
    const isIntl = locate?.shipIntl && whichCntry() !== 'US'

    new_vars.forEach(nvar => {
      const json_var = prodInv?.variants?.find(x => x.id === nvar.id)
      let orig_avail = nvar.available

      nvar.inv = json_var ? json_var.inv : nvar?.inv ? JSON.parse(nvar.inv) : {}
      nvar.available = json_var ? json_var.available : orig_avail
      nvar.shop_avail = nvar.available 

      const whinv = !!nvar.inv?.DW || !!nvar.inv['BL - Ecommerce']

      nvar.ship = (isSHM && json_var) ? whinv : orig_avail
      nvar.pickup = !!Object.values(nvar.inv).some(x => !!x) || false

      if (!!prodInv?.fulfill) {
        let var_title = nvar.title
        if (var_title.toLowerCase() === 'one size') {
          var_title = 'OS'
        }
        const var_fulfill = prodInv.fulfill[var_title]
        const var_ship = !!var_fulfill?.ship || false
        const var_pickup = !!var_fulfill?.pickup || false

        if (isIntl) nvar.available = var_ship
        nvar.ship = !isSHM ? var_ship : whinv 
        nvar.pickup = var_pickup
      }
    })

    return formatVarTitles(new_vars)
  }

  const checkCta = ({variants, pdpVariant=false}) => {
    if (!pdpVariant) {
      return !!variants.find(x => x.available && (x?.ship || x?.pickup))
    }
    return pdpVariant.available && (pdpVariant?.ship || pdpVariant?.pickup)
  }

  const checkShipPick = ({variants, prodInv=false}) => {
    const source = prodInv?.fulfill ? Object.values(prodInv.fulfill) : variants
    return {ship: source.some(x => x.ship), pickup: source.some(x => x.pickup)}
  }

  const checkLow = ({variants, pdpVariant=false, isGift=false}) => {
    if (isGift) return false
    if (!variants || variants.length < 1) return false
    let var_inv
    const check_size = pdpVariant ? variants.find(x=> x.title === pdpVariant.title) : false
    if (check_size) {
      var_inv = check_size?.inv ? [check_size.inv] : []
    } else {
      var_inv = variants.map(x => x.inv).filter(x => Object.keys(x).length > 0)
    }
    if (var_inv.length > 0) {
      var_inv = var_inv.map(inv => {
        const inv_shorts = Object.keys(inv)
        const var_qty = inv_shorts.map(x => inv[x] === true ? 5 : inv[x] === false ? 0 : inv[x])
        return var_qty
      }).flat()
      var_inv = var_inv.reduce((total, x) => total + x, 0)
    }

    let new_low = null
    if ((pdpVariant && var_inv <= 25) || !pdpVariant) new_low = var_inv
    return new_low
  }

  const getOutfits = (props) => {
    const { outfits, outfills, prod_gender, gender } = props

    // let to_set
    const is_test = (typeof sessionStorage !== 'undefined') && sessionStorage.getItem('outftest')

    if (!is_test) {
      // to_set = outfits.concat(outfills).filter(x => !!x)
      return outfits.filter(x => !!x)
    }

    if (prod_gender === 'u') {
      if (gender === 'men') return outfits
      if (gender === 'women') return outfills
      return
    }
    return outfits.concat(outfills).filter(x => !!x)
  }


  return { checkCta, checkShipPick, checkLow, setupProduct, setupVariants, whichCntry, getOutfits }
}

export default PdpInit