import React, { useState, useEffect, useRef } from "react"
import Quick from '~/templates/quick'
import { useTheme } from '~/contexts/ThemeContext'
import { usePdp } from '~/contexts/PdpContext'
import useAnalytics from '~/utils/analytics'

import StoresPdpPanel from '~/components/panels/stores-pdp'

import { bg } from "~/styles/app.module.scss"
import * as styles from "~/styles/panels/quick.module.scss"


const QuickWrap = ({ quick_data }) => {
  const { setQuick, setFreeze, storesOpen, isMobile, pdpVariant } = useTheme()
  const { product, showStoresPanel } = usePdp()
  const { ga_quick } = useAnalytics()

  const bgRef = useRef(null)
  const panelRef = useRef(null)

  const [blockKey, setBlockKey] = useState(false)

  const handleClose = () => {
    if (!bgRef.current || !panelRef.current) return

    if (document.activeElement && panelRef.current.contains(document.activeElement)) {
      document.activeElement.blur()
    }
    
    bgRef.current.setAttribute('aria-hidden', 'true')
    panelRef.current.setAttribute('aria-hidden', 'true')
    setTimeout(() => {
      setFreeze(false)
      setQuick(false)
    }, 200)
  }

  const colorClick = (handle) => {
    const new_products = quick_data.products.map(x => x)
    const new_quick = new_products.find(x => x.handle === handle)
    if (new_quick) {
      const obj = {selected: new_quick.shop_id, products: new_products}
      setQuick(obj)
    }
  }

  useEffect(() => {
    const bg_el = bgRef.current
    const panel_el = panelRef.current

    setFreeze(true)
    if (product) {
      // if (!!quick_data.variant) handleVariantSelect(quick_data.variant.id)
      // if (is_single) handleVariantSelect(variants[0].id)
      setTimeout(() => {
        bg_el.setAttribute('aria-hidden', 'false')
        panel_el.setAttribute('aria-hidden', 'false')
      }, 100)
      ga_quick(product)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filtersListen = (e) => {
      if (blockKey) return
      if (e.key === 'Escape') {
        window.removeEventListener('keydown', filtersListen)
        handleClose()
      }
    }
    if (!isMobile) window.addEventListener('keydown', filtersListen)

    return () => {
      window.removeEventListener('keydown', filtersListen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockKey])

  useEffect(() => {
    if (blockKey !== storesOpen) setBlockKey(!!storesOpen)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storesOpen, blockKey])


  return (
    <>
      <div>
        <button 
          type="button"
          className={bg} 
          aria-hidden="true" 
          ref={bgRef} 
          onClick={(e)=> {handleClose()}} 
          aria-label={'Close quick buy panel'}
        />
        <aside 
          className={styles.quick}
          ref={panelRef}
          id="ar_sidebar-p-prod" 
          aria-label="Product Details" 
          role="dialog" 
          aria-modal="true" 
          aria-hidden="true"
        >
          <Quick key={product.id} handleClose={handleClose} colorClick={colorClick} />
        </aside>
      </div>
      {showStoresPanel && (
        <StoresPdpPanel variant={pdpVariant} />
      )}
    </>
  );
}

export default QuickWrap
