import React, { useState, useEffect, useRef } from "react"
import { useTheme } from '~/contexts/ThemeContext'
import { usePdp } from '~/contexts/PdpContext'
import SetLoc from '~/utils/set-loc'
import { jnstyls } from '~/utils/helpers'

import { PdpRegion, PdpStore } from './stores-pdp-region'
import IconClose from '~/components/icons/close'
import IconLoc from '~/components/icons/location'

import { aria_btn, solid_btn, bg, qsizes } from "~/styles/app.module.scss"
import * as styles from "~/styles/stores/store_panel.module.scss"

const StoresPdpPanel = () => {
  const { isMobile, setFreeze, locate, setLocate, pdpVariant, collPanel, storesData } = useTheme()
  const { 
    variants, onVariantSelect, nearInstock, farInstock, currStore, setCurrStore, 
    setShowStoresPanel, anyStores
  } = usePdp()

  const { checkZip, deviceLoc } = SetLoc()

  const bgRef = useRef(null)
  const panelRef = useRef(null)
  const inputRef = useRef(null)

  const [didLoad, setDidLoad] = useState(false)
  const [cartStore, setCartStore] = useState(false)
  const [inputKey, setInputKey] = useState('zip-input-1')

  const handleClose = (both=false) => {
    if (!bgRef.current || !panelRef.current) return

    if (document.activeElement && panelRef.current.contains(document.activeElement)) {
      document.activeElement.blur()
    }
    bgRef.current.setAttribute('aria-hidden', 'true')
    panelRef.current.setAttribute('aria-hidden', 'true')
    
    setTimeout(() => {
      if (!(both && collPanel)) setFreeze(false)
      setShowStoresPanel(false)
    }, 100)
  }

  const handleNewSize = (newVariant) => {
    onVariantSelect(newVariant.id)
  }

  const selectStore = (store, isUser=false) => {
    if (cartStore === store.title) {
      setCartStore(false)
      return
    }
    setCartStore(store.title)
    setCurrStore(store)

    if (isUser) {
      setLocate({
        ...locate,
        pdpStore: store.short_name,
      })
      
      window._conv_q = window._conv_q || []
      // Goal: 100464618 = Pickup: Selected Store
      window._conv_q.push(["triggerConversion", "100464618"])
    }
  }

  const handleZip = async (e) => {
    if (storesData.length < 1) return
    const types = ['insertText','insertFromPaste']
    if (!types.includes(e.nativeEvent.inputType)) return

    const val = inputRef.current.value
    if (val && val.length >= 5) {
      checkZip(val)
    }
  }

  const handleZipClick = async () => {
    const val = inputRef.current.value
    if (val && val.length >= 5) {
      checkZip(val)
    }
  }

  const handleDevice = async () => {
    inputRef.current.value = 'Checking location...'
    const device = await deviceLoc()
    return device
  }

  useEffect(() => {
    const storesListen = (e) => {
      if (e.key === 'Escape') {
        window.removeEventListener('keydown', storesListen)
        handleClose()
      }
    }
    if (!isMobile) window.addEventListener('keydown', storesListen)

    return () => {
      window.removeEventListener('keydown', storesListen)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (didLoad || storesData.length < 1) return
    setFreeze(true)

    const bg_el = bgRef.current
    const panel_el = panelRef.current

    setDidLoad(true)

    setTimeout(() => {
      bg_el.setAttribute('aria-hidden', 'false')
      panel_el.setAttribute('aria-hidden', 'false')
    }, 100)
  }, [storesData, setFreeze, didLoad])

  useEffect(() => {
    if (!locate) return
    setInputKey('zip-input-' + Date.now())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locate])

  

  return (
    <>
      <button 
        type="button"
        className={bg} 
        aria-hidden="true" 
        ref={bgRef} 
        onClick={(e)=> {handleClose()}}
        aria-label={'Close stores panel'}
      />
      <aside 
        className={styles.store_panel}
        ref={panelRef}
        id="ar_sidebar-p-stores" 
        aria-label="Choose a store to filter by" 
        role="dialog" 
        aria-modal="true" 
        aria-hidden="true"
      >
        <div className={jnstyls([styles.head, styles.pdp_head])}>
          <button 
            type="button"
            className={jnstyls([aria_btn, styles.close])}
            aria-label="Close stores sidebar"
            onClick={(e)=> {handleClose()}} 
          >
            <IconClose icon_classes={''} />
          </button>

          <div className={styles.search}>
            <input 
              key={inputKey}
              id="stores-search_input"
              inputMode="numeric" 
              type="text" 
              aria-label="Enter Zip Code to Find Closest Store"
              defaultValue={locate ? locate?.usrZip || locate.zip : undefined}
              placeholder="Enter zip code to find your store"
              onInput={(e) => handleZip(e)}
              ref={inputRef}
            />
            <button 
              type="button"
              className={styles.loc_btn} 
              aria-label="Find your location"
              onClick={(e) => handleDevice()}
            >
              <IconLoc />
            </button>
            <button 
              className={styles.search_btn} 
              aria-label="Submit search"
              onClick={(e) => handleZipClick()}
            />
          </div>

          <div className={styles.nsizes}>
            <br/>
            <div className={jnstyls([qsizes, styles.sizes])}>
              {variants.map( mapvar => 
                <button 
                  type="button"
                  key={mapvar.id}
                  className={aria_btn} 
                  aria-label={`Add size ${mapvar.title} to cart`} 
                  disabled={mapvar?.pickup ? undefined : 'disabled'}
                  aria-pressed={mapvar.title === pdpVariant.title ? 'true' : 'false'}
                  onClick={(e)=> handleNewSize(mapvar)}
                >
                  {mapvar.title}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className={jnstyls([styles.content, styles.pdp_content])}>

          <div className={styles.regions}>
            {nearInstock.length > 0 && nearInstock.map( (store) => 
              <PdpStore 
                key={store.id}
                store={store}
                regionOpen={true}
                selectStore={selectStore} 
              />
            )}
            {farInstock.length > 0 && farInstock.map( (region, index) => 
              <PdpRegion 
                key={`${region.region}`}
                regionData={region} 
                selectStore={selectStore} 
                showOpen={nearInstock.length < 1}
              />
            )}
          </div>
        </div>

        
        <div className={styles.back}>
          <button 
            type="button"
            className={jnstyls([aria_btn, solid_btn])}
            onClick={(e) => handleClose(true)}
          >
            {(cartStore || anyStores.includes(currStore?.short_name)) ? 'Save Selected Store' : 'Continue Shopping'}
          </button>
        </div>
      </aside>
    </>
  )
}

export default StoresPdpPanel






