import React, { useState, useEffect } from "react";
import { useSwipeable } from 'react-swipeable'
import { navigate } from "gatsby";
import { useTheme } from '~/contexts/ThemeContext'
import { useCart } from '~/contexts/CartContext'
import useAnalytics from '~/utils/analytics'
import { imgSize, notBlank } from '~/utils/helpers'

import { IconChevLeft, IconChevRight } from '~/components/icons/chevrons'

import { aria_btn } from "~/styles/app.module.scss"
import * as styles from "~/styles/panels/cart.module.scss"


const CartUpsell = ({ recProds, groupTitle='You Might Also Like', type='both' }) => {
  const { setFreeze, isTouches, analytics, setAnalytics, quick } = useTheme()
  const { setCartOpen } = useCart()
  const { elev_select, ga_tile_click } = useAnalytics()
  
  const { recs, outfits } = recProds
  const [items, setItems] = useState([])  
  const [pos, setPos] = useState(0)

  const handleClick = (num) => {
    let change = pos + (num * 2)
    if (change >= items.length) change = 0
    if (change < 0) change = items.length - 2 
    setPos(change)
  }

  const handleLink = (product, tile_ind) => {
    if (!!quick) {
      window._conv_q = window._conv_q || []
      window._conv_q.push(["triggerConversion", "100488617"])
    }
    setFreeze(false)
    setCartOpen(false)
    setAnalytics({...analytics, list: 'cart recommendation'})
    navigate(`/products/${product.handle}`)
    ga_tile_click(product, tile_ind, 'cart recommendation')
    elev_select(product, tile_ind, 'cart recommendation')
  }

  const pick_img = (item) => {
    let image = item.image
    if (!image && item.media && item.media.find(x => x.type === 'IMAGE')) {
      image = item.media.find(x => x.flat) ? item.media.find(x => x.flat) : item.media.find(x => x.type === 'IMAGE')
      image = image.src
    }

    return imgSize(image, 300)
  }

  const swipers = useSwipeable({
    onSwiping: (e) => {
      if (!isTouches) return
    },
    onSwipedLeft: (e) => {
      if (!isTouches ) return
      handleClick(1)
    },
    onSwipedRight: (e) => {
      if (!isTouches) return
      handleClick(-1)
    },
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: true,
    delta: {
      up: 1000,
      down: 1000,
    },
  });
  
  const checkShow = (index) => {
    return (index !== pos && index !== pos + 1).toString()
  }

  const setupItems = () => {
    let products
    if (type === 'both') {
      products = outfits.concat(recs).slice(0,6)
    }
    if (type === 'recs') {
      products = recs.slice(0,6)
    }
    if (type === 'outfits') {
      products = outfits.slice(0,6)
    }
    setItems(products)
  }

  useEffect(() => {
    setupItems()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className={styles.upsell} c-ups=''>
      <div className={styles.recs_head}>
        <div className={styles.title}>
          {groupTitle}
        </div>
        <div className={styles.rec_mores}>
          <button 
            type="button"
            className={`${aria_btn} ${styles.rec_more} ${pos !== 0 ? '' : styles.off}`} 
            disabled={pos !== 0 ? undefined : 'disabled'}
            onClick={(e)=>{handleClick(-1)}}
            aria-label='Previous recommendations'
          >
            <IconChevLeft icon_classes="" />
          </button>
          <button 
            type="button"
            className={`${aria_btn} ${styles.rec_more} ${(pos + 2 >= items.length) ? styles.off : ''}`}
            disabled={pos + 2 >= items.length ? 'disabled' : undefined}
            onClick={(e)=>{handleClick(1)}}
            aria-label='Next recommendations'
          >
            <IconChevRight icon_classes="" />
          </button>
        </div>
      </div>

      <div className={styles.recs} {...swipers}>
        {items.length > 0 && items.map( (item, index) => 
          <button 
            type="button"
            key={item.handle} 
            aria-hidden={checkShow(index)} 
            className={[aria_btn, styles.rec].join(' ')}
            onClick={(e)=>{handleLink(item, index)}}
          >
            <div className={styles.rec_image}>
              <img 
                className={styles.img} 
                src={pick_img(item)}
                alt={`Buck Mason ${item.title}`} 
                height="300" 
                width="200" 
              />
            </div>
            <div className={styles.rec_content}>
              <div className={styles.title}>
                {notBlank(item.pl_name) ? item.pl_name : item.title}
              </div>
              {notBlank(item.color) && (
                <div className={styles.color}>
                  {item.color}
                </div>
              )}
              <div className={styles.price}>
                ${item.first_product_price}
              </div>
            </div>
          </button>
        )}
      </div>
    </div>
  )
}


export default CartUpsell



