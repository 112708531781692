import useRecommendHook from '~/utils/recommends'

const usePdpOtherDataHook = ({ product, setRecProds, setReviews }) => {
  const { getRecs } = useRecommendHook()


  // Recommends ---------------------------------------------------------------

  const getRecsJson = async () => {
    const recs = await getRecs(product.mid, product.product_line.mid)
    // const is_womens = product.gender === 'w'
    
    setRecProds(recs)

    // on model for womens
    // if (product.gender !== 'w') {
    //   setRecProds(recs)
    //   return
    // }
    // getNonFlats(recs)
  }

  const getPdpRecs = async () => {
    const data = await getProdJson(product.handle)
    
    if (data?.recommends?.length > 0) setRecProds(data.recommends)
    // setRecProds(recs)

    // on model for womens
    // if (product.gender !== 'w') {
    //   setRecProds(recs)
    //   return
    // }
    // getNonFlats(recs)
  }

  const getProdJson = async (handle) => {
    const response = await fetch(`/page-data/products/${handle}/page-data.json`)
    const data = await response.json()
    return new Promise((resolve, reject) => resolve(data?.result?.data?.product))
  }

  // const getNonFlats = async (products) => {
  //   let rproducts = structuredClone(products)
  //   for (const rproduct of rproducts) {
  //     const json = await getProdJson(rproduct.handle)
  //     if (!json || json?.media?.length < 2) continue
  //     const images = json.media.filter(x => x.type === 'IMAGE' && !x.flat)
  //     if (images.length < 1) continue
  //     rproduct.image = images[0].src
  //   }
  //   setRecProds(rproducts)
  // }

  // Reviews ------------------------------------------------------------------

  const getReviews = async () => {
    const product_line_id = product.product_line.mid
    const url = `https://cdn.buckmason.com`
    const api_req = await fetch(`${url}/reviews/${product_line_id}.json?key=${process.env.LOCAL_API_KEY}`)
    if (!api_req.status === 200) {
      setReviews([])
      return 
    }
    const data = await api_req.json()
    if (data && data?.length > 0) {
      setReviews(data)
    }
  }

  return { 
    getRecsJson, getReviews, getProdJson, getPdpRecs
  }
}


export default usePdpOtherDataHook





// reviews local testing
// const url = process.env.GATSBY_CURR_ENV === 'production_local' ? 
//             `https://s3.us-west-1.amazonaws.com/mokah-livestaging`
//             : `https://cdn.buckmason.com`



