import QlReq from '~/utils/ql-req'
import * as Queries from '~/helpers/ql-queries'

const UseWardrobe = () => {

  const checkKnown = (customer) => {
    if (customer && customer.full) return true
    if (!customer) return false

    const knwn = (typeof localStorage !== "undefined") && localStorage.getItem('knwn')
    return knwn ? customer.id.includes(knwn) : false
  }

  const getUrl = () => {
    // const local = process.env.GATSBY_CURR_ENV !== 'production' && false
    // const url = `${local ? 'http://localhost:8080' : 'https://bmfavs.vercel.app'}/api`
    const url = `https://bmfavs.vercel.app/api`
    return url
  }

  const checkUserFavs = async(obj) => {
    const headers = {
      'Content-Type': 'application/json'
    }
    if (process.env.GATSBY_CURR_ENV !== 'production') headers['x-local-api-key'] = process.env.LOCAL_API_KEY

    if (obj.hasOwnProperty('sid')) obj.sid = obj.sid.replace('gid://shopify/Customer/','')

    const response = await fetch(`${getUrl()}/favs`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(obj)
    })
    if (response.ok) {
      const data = await response.json()
      return data
    } else {
      return null
    }
  }

  const checkShopUser = async(obj) => {
    const stag = process.env.GATSBY_CURR_ENV !== 'production'
    const headers = {
      'Content-Type': 'application/json'
    }
    if (stag) headers['x-local-api-key'] = process.env.LOCAL_API_KEY

    if (obj?.shid) {
      obj.shid = obj.shid.replace('gid://shopify/Customer/','')
    }
    obj.action = 'check'

    try {
      const response = await fetch(`${getUrl()}/users`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(obj)
      })
      if (response.ok) {
        const data = await response.json()
        if (stag) console.log(data)
        return data
      }
      return null
    } catch (error) {
      return null
    } 
  }

  const checkShopUserEmail = async(email) => {
    const headers = {
      'Content-Type': 'application/json'
    }
    if (process.env.GATSBY_CURR_ENV !== 'production') headers['x-local-api-key'] = process.env.LOCAL_API_KEY

    const obj = {
      email: email,
      action: 'check'
    }
    
    const response = await fetch(`${getUrl()}/users`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(obj)
    })
    if (response.ok) {
      const data = await response.json()
      return data
    } else {
      return null
    }
  }

  // Change the user check to setting a cookie that expires in a day
  // and if no favs then stop theme from checking when a user in signed
  // in.

  // remove email from actual use if go live

  const createFavUser = async(customer, pid) => {
    const headers = {
      'Content-Type': 'application/json',
    }
    if (process.env.GATSBY_CURR_ENV !== 'production') headers['x-local-api-key'] = process.env.LOCAL_API_KEY


    const data = {
      id: customer.id.replace('gid://shopify/Customer/',''), 
      name: customer.firstName, 
      email: customer.email, 
      pid: pid
    }

    const response = await fetch(`${getUrl()}/users`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
    if (response.ok) {
      const data = await response.json()
      localStorage.setItem('wrdrb', data.id)
      return data
    } else {
      return null
    }
  }

  const updFavs = async(id, pid) => {
    const headers = {
      'Content-Type': 'application/json',
    }
    if (process.env.GATSBY_CURR_ENV !== 'production') headers['x-local-api-key'] = process.env.LOCAL_API_KEY


    const data = { id, pid, action: 'upd'}

    const response = await fetch(`${getUrl()}/favs`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
    if (response.ok) {
      const data = await response.json()
      return data
    } else {
      return {}
    }
  }

  const checkLive = async (product) => {
    const { handle, sz, pl_ind } = product
    const data = await getProdJson(handle)
    const variant = data?.variants?.find(x => x.title === sz)

    if (variant?.available) return product

    if (!variant?.available && Object.keys(pl_ind.lv).length < 1) {
      product.live = false
      product.pl_other = false
      return product
    }

    product.live = false
    product = await checkPlOthers(product)
    
    return product
  }

  const checkPlOthers = async (product) => {
    const { pl_ind, sz } = product

    const lives = pl_ind?.lv ? Object.values(pl_ind.lv) : []

    let found = false
    for (let other_hand of lives) {
      const other_data = await getProdJson(other_hand)
      const other_var = other_data?.variants?.find(x => x.title === sz)
      if (!!other_var && other_var.available) {
        product.pl_other = other_hand
        found = true
        break
      }
    }
    if (!found) product.pl_other = false

    return product
  }

  const fillOrderProds = async (orders, prod_index) => {
    if (orders.length < 1) return []
    
    let products = orders.flatMap(x => x.itms)
    products = products.filter(x => x.m.length > 0 && !x.h.includes('gift-card'))
    products = [...new Map(products.map(item => [item.ppid, item])).values()]

    let handles_data = await fetch('/page-data/api-data/handles/page-data.json')
    handles_data = await handles_data.json()
    handles_data = handles_data?.result?.data?.allProduct?.nodes || []
    handles_data = handles_data.map(x => x.handle)

    const colors = Object.values(prod_index.colors)
    
    products = products.map(item => {
      let pl_ind = prod_index.pls.find(x => x.pid === item.pid)
      // if (!pl_ind) pl_ind = prod_index.pls.find(x => x.pid === item.pid)
      const lives = pl_ind?.lv ? Object.keys(pl_ind.lv) : []
      const pl_live = lives.length > 0
      const is_live = handles_data.includes(item.h)

      let pl_other = false
      if (!is_live && pl_live) pl_other = pl_ind.lv[Object.keys(pl_ind.lv)[0]]

      const prod = {
        ...item,
        color: colors.find(x => x.nm === item.c_nm),
        handle: is_live ? pl_ind.lv[item.ppid] : '',
        pl_name: pl_ind?.name,
        category: pl_ind?.pc,
        type: pl_ind?.pt,
        live: is_live,
        pl_live: pl_live,
        pl_other: pl_other,
        gender: pl_ind?.g,
        pl_ind: pl_ind
      }
      return prod
    })

    for (let product of products) {
      if (!product.live && !product.pl_other) continue

      if (product.live) {
        product = await checkLive(product)
      
      } else if (product.pl_other) {
        product = await checkPlOthers(product)
      }
    }

    products = products.filter(x => !x.pl_name?.includes('Face Mask'))

    return products
  }

  const getProdJson = async (handle) => {
    const response = await fetch(`/page-data/products/${handle}/page-data.json`, {
      headers: {
        'Cache-Control': 'max-age=3600'
      }
    })
    const data = await response.json()
    return new Promise((resolve, reject) => resolve(data?.result?.data?.product))
  }

  const preloadProductJson = (handles) => {
    handles.forEach(handle => {
      const link = document.createElement('link')
      link.rel = 'preload'
      link.as = 'fetch'
      link.href = `/page-data/products/${handle}/page-data.json`
      link.crossOrigin = 'anonymous'
      document.head.appendChild(link)
    })
  }

  const allProdJson = async (handles) => {
    preloadProductJson(handles)
    const reqs = await Promise.all( handles.map(x => getProdJson(x)) )
    return reqs.filter(x => !!x)
  }

  const getOrderHist = async (customer) => {
    let loc_orders = checkNewOrders(customer)
    let order_json
    if (loc_orders && loc_orders?.ords?.length > 0) {
      order_json = loc_orders.ords
    } else {
      order_json = await reqOrderHist(customer)
      localStorage.setItem('ords', JSON.stringify({dt: Date.now(), ords: order_json.orders, bads: order_json.bads}))
    }
    if (order_json.length < 1) localStorage.removeItem('ords')
    return order_json.orders
  }

  const reqOrderHist = async (customer) => {
    const headers = {
      'Content-Type': 'application/json',
    }
    if (process.env.GATSBY_CURR_ENV !== 'production') headers['x-local-api-key'] = process.env.LOCAL_API_KEY
    const data = {sid: customer.id.replace('gid://shopify/Customer/',''), action: 'hist'}

    const response = await fetch(`${getUrl()}/users`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })

    if (response.ok) {
      const data = await response.json()
      return data
    } else {
      return []
    }
  }

  const checkNewOrders = async (customer) => {
    let loc_orders = localStorage.getItem('ords')
    if (!loc_orders) return null
    loc_orders = JSON.parse(loc_orders)

    loc_orders.ords = loc_orders.ords.slice(3,loc_orders.ords.length)

    let vars = {
      customerAccessToken: customer.token,
    }

    let query = Queries.q_order_ids()
    let data = await QlReq.post(query, vars)
    
    if (!data?.customer?.orders) return loc_orders
    const order_ids = data.customer.orders.nodes.map(x => x.id.replace('gid://shopify/Order/','').split('?')[0])
    const exist_ids = loc_orders.ords.map(x => x.sid).concat(loc_orders.bads)
    const missing = order_ids.filter(x => !exist_ids.includes(x))
    
    if (missing.length < 1) return loc_orders

    vars = {
      customerAccessToken: customer.token,
      first: missing.length
    }

    query = Queries.q_owned_prods()
    data = await QlReq.post(query, vars)

    if (!data || data?.errors?.length > 0) return loc_orders

    data = data.customer.orders.nodes
    if (data.length < 1) return loc_orders

    data = parseOrders(data)
    loc_orders.ords = data.orders.concat(loc_orders.ords)
    loc_orders.bads = data.bads.concat(loc_orders.bads)
    localStorage.setItem('ords', JSON.stringify(loc_orders))
    return loc_orders
  }

  const parseOrders = (data) => {

    let bads = data.filter(x => x.canceledAt !== null).map(x => x.id)
    let orders = data.filter(x => x.canceledAt === null)
    
    orders.forEach(order => { 
      order.sid = order.id.replace('gid://shopify/Order/', '')
      order.pat = order.processedAt
      order.itms = order.lineItems.nodes
      order.itms = order.itms.filter(item => item.currentQuantity > 0)
      order.itms.forEach(item => {
        if (!item.variant?.product?.handle) return
        item.h = item.variant.product.handle
        item.q = item.quantity
        item.sz = item.variant.title
        item.c_nm = item.variant.product.mPimacolor?.value?.toLowerCase()
        item.pid = item.variant.product.mPimaplid?.value
        if (item.pid) item.pid = parseInt(item.pid)
        item.ppid = item.variant.product.mPimaid?.value
        if (item.ppid) item.ppid = parseInt(item.ppid)
        let media = item.variant.product.media.nodes
        media = media.filter(media => media.mediaContentType === 'IMAGE')
        const flat = media.find(media => media.alt.includes('product flat'))
        media = media.map(media => media.previewImage.url)
        if (flat) media.unshift(flat.previewImage.url)
        item.m = media.slice(0, 2).map(url => imgcdn(url))
        item.sid = item.variant.product.id.replace('gid://shopify/Product/', '')

        delete item.variant
        delete item.currentQuantity
      })
      delete order.processedAt
      delete order.cancelledAt
      delete order.id
      delete order.lineItems
      order.itms = order.itms.filter(item => !item?.variant)
      order.itms = order.itms.filter(item => item?.m && item.m.length > 0)
    })
    bads = bads.concat(orders.filter(order => order.itms.length < 1).map(order => order.sid))
    orders = orders.filter(order => order.itms.length > 0)
    bads = bads.map(x => x.replace('gid://shopify/Order/', ''))

    return {orders, bads}
  }

  const imgcdn = (url) => {
    const cdnp = 'https://cdn.shopify.com/s/files/1/0123/5065/2473/products'
    const cdnf = 'https://cdn.shopify.com/s/files/1/0123/5065/2473/files'

    if (url?.includes(cdnp)) url = url.replace(cdnp, 'cdnp:')
    if (url?.includes(cdnf)) url = url.replace(cdnf, 'cdnf:')

    return url
  }

  return { 
    checkUserFavs, createFavUser, updFavs, getOrderHist, getProdJson, allProdJson,
    fillOrderProds, checkNewOrders, checkShopUser, checkShopUserEmail, checkKnown
  }
}

export default UseWardrobe












