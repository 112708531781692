import React, { useMemo } from "react"
// import { ProductProvider } from '~/contexts/ProductContext'
import { PdpProvider } from '~/contexts/PdpContext'
import PdpInit from '~/initializers/pdp-init'
import QuickWrap from '~/components/panels/quick-wrap'



const QuickContext = ({ quick_data }) => {
  const { setupProduct, setupVariants } = PdpInit()
  const product = quick_data.products.find(x => x.shop_id === quick_data.selected)

  const productData = useMemo(() => {
    return setupProduct(product)
  }, [product])

  const variantData = useMemo(() => {
    return setupVariants({variants: productData.variants, isGift: productData.is_gift})
  }, [productData])

  return (
    <PdpProvider key={productData.id} productData={productData} variantData={variantData} isQuick={true}>
      <QuickWrap key={productData.id} quick_data={quick_data} />
    </PdpProvider>
  );
}

// <ProductProvider key={product.id} productData={product} isQuick={true}>
//   <QuickWrap key={product.id} quick_data={quick_data} />
// </ProductProvider>

export default QuickContext
