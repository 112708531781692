import * as React from "react"
import { Link } from "gatsby"


const Flink = React.forwardRef(({ href='', children, ...props }, ref) => {
  const is_ext_link = (href.includes('http:') || href.includes('https:')) && !(href.includes('www.buckmason.com') && href.includes('localhost'))
  const prefetch = !is_ext_link && (href.includes('/collections/') || href.includes('/products/'))

  const cleanLink = (url) => {
    return url.replace('https://www.buckmason.com','')
  }

  return is_ext_link ? (
    <a
      href={href}
      {...props}
      rel={'noopener nofollow'}
      ref={ref}
    >
      {children}
    </a>
  ) : (prefetch) ? (
    <Link
      to={cleanLink(href)}
      ref={ref}
      onMouseEnter={(e) => {
        if (e.target instanceof HTMLAnchorElement) {
          e.target.setAttribute('data-gatsby-preload', '')
        }
      }}
      {...props}
    >
      {children}
    </Link>
  ) : (
    <Link
      to={cleanLink(href)}
      ref={ref}
      {...props}
    >
      {children}
    </Link>
  )
})

export default Flink




