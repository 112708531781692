import React, { useState, useEffect, useRef } from "react"
import { useTheme } from '~/contexts/ThemeContext'
import { useCart } from '~/contexts/CartContext'
// import useCartHook from '~/hooks/useCartHook'
import Modal from '~/components/shared/modal'

import { aria_btn, bg, solid_btn, field, fieldset } from "~/styles/app.module.scss"
import * as styles from "~/styles/pdp/giftCard.module.scss"


const GiftCard = () => {
  const { setFreeze } = useTheme()
  const { cartApi, cartOpen, setCartOpen, giftInfo, setGiftInfo, setShowGiftCard } = useCart()
  const { changeLine } = cartApi

  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [date, setDate] = useState(null)
  const [message, setMessage] = useState(null)

  const bgRef = useRef(null)
  const formRef = useRef(null)

  const onClose = () => {
    handleClose()
  }
  const handleClose = () => {
    if (bgRef.current) bgRef.current.setAttribute('aria-hidden', 'true')
    setShowGiftCard(false)
  }

  const handleSubmit = ( line ) => {
    const fields = ['name', 'email', 'date', 'message']
    let attrs = []

    fields.forEach(x => {
      const form_field = formRef.current.querySelector(`[gift-form="${x}"]`)
      if (!form_field.value) return
      const field_name = form_field.name
      attrs.push({key: field_name, value: form_field.value})
    })

    const obj = {attributes: attrs}

    if (!giftInfo.line) {
      setGiftInfo(obj)
      return
    }
    
    changeLine(line, obj)
    setCartOpen(true) 
    setGiftInfo(false)
    handleClose()
  }

  useEffect(() => {
    setFreeze(true)

    return () => {
      if (!cartOpen) setFreeze(false)
      setShowGiftCard(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!giftInfo.line) return
    giftInfo.line.attributes.forEach(x => {
      const form_name = formRef.current.querySelector(`[name="${x.key}"]`).getAttribute('gift-form')
      if (form_name === 'name') setName(x.value)
      if (form_name === 'email') setEmail(x.value)
      if (form_name === 'date') setDate(x.value)
      if (form_name === 'message') setMessage(x.value)
    })
  }, [giftInfo.line])

  return (
    <div className={styles.gcwrap}>
      <div 
        className={bg} 
        aria-hidden="true" 
        ref={bgRef} 
        style={{zIndex: '501'}}
      />
      <Modal name={'GiftCard'} childStyles={''} onModalClose={onClose}>
        <div className={styles.title} id="armodal_lab-giftcard">
          Who’s the lucky recipient?
        </div>
        <div className={styles.form} ref={formRef}>
          <div className={fieldset}>
            <div className={field}>
              <label htmlFor="recipient-name-input">
                Recipient Name
              </label>
              <input  
                type="text" 
                placeholder="Name" 
                require="require" 
                data-auto-focus="true" 
                id="recipient-name-input" 
                name="Recipient Name" 
                defaultValue={name ? name : ''} 
                gift-form="name" 
                required="required" 
              />
            </div>
            <div className={field}>
              <label htmlFor="recipient-email-input">
                Recipient Email
              </label>
              <input 
                type="email" 
                placeholder="name@email.com" 
                name="Recipient Email" 
                defaultValue={email ? email : ''} 
                id="recipient-email-input" 
                gift-form="email" 
                required="required"
              />
            </div>
          </div>
          <div className={fieldset}>
            <div className={field}>
              <label htmlFor="recipient-date-input">
                Delivery Date (optional)
              </label>
              <input 
                type="date" 
                placeholder="MM/DD/YYYY" 
                name="Delivery Date" 
                defaultValue={date ? date : ''} 
                id="recipient-date-input" 
                gift-form="date"
              />
            </div>
            <div className={field}>
              <p className={styles.copy}>
                A Digital Gift Card will be sent immediately following transaction unless a date is specified.
              </p>
            </div>
          </div>
          <div className={field}>
            <label htmlFor="recipient-msg-area">
              Message (optional)
            </label>
            <textarea 
              id="recipient-msg-area" 
              name="Message" 
              defaultValue={message ? message : ''} 
              gift-form="message"
            ></textarea>
          </div>
          <div className={styles.submitWr}>
            <button type="button" className={solid_btn} onClick={(e)=>{ handleSubmit(giftInfo.line ? giftInfo.line : false)}}>
              {giftInfo.line ? 'Update Gift Card' : 'Add Card to Cart'}
            </button>
          </div>
        </div>

      </Modal>
    </div>
  )
};

export default GiftCard




