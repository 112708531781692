import React, { useMemo } from "react"
import { navigate } from "gatsby"
import { useTheme } from '~/contexts/ThemeContext'
import { useCart } from '~/contexts/CartContext'
import useAnalytics from '~/utils/analytics'
import { imgSize, notBlank } from '~/utils/helpers'

import { aria_btn } from "~/styles/app.module.scss"
import * as styles from "~/styles/panels/quick.module.scss"

const QuickRecs = ({ recProds }) => {
  const { setFreeze, analytics, setAnalytics } = useTheme()
  const { setCartOpen } = useCart()
  const { elev_select, ga_tile_click } = useAnalytics()
  
  const { recs, outfits } = recProds

  const rec_products = useMemo(() => recs.slice(0,6), [recs]) 
  const outf_products = useMemo(() => outfits.slice(0,6), [outfits]) 


  const handleLink = (product, tile_ind) => {
    setFreeze(false)
    setCartOpen(false)
    window._conv_q = window._conv_q || []
    window._conv_q.push(["triggerConversion", "100488617"])
    setAnalytics({...analytics, list: 'cart recommendation'})
    navigate(`/products/${product.handle}`)
    ga_tile_click(product, tile_ind, 'cart recommendation')
    elev_select(product, tile_ind, 'cart recommendation')
  }

  const pick_img = (item) => {
    let image = item.image
    if (!image && item.media && item.media.find(x => x.type === 'IMAGE')) {
      image = item.media.find(x => x.flat) ? item.media.find(x => x.flat) : item.media.find(x => x.type === 'IMAGE')
      image = image.src
    }

    return imgSize(image, 300)
  }


  return (
    <div className={styles.qrecs_wr}>
      {(outf_products && outf_products?.length > 0 ) && (
        <>
          <div className={styles.qrecs_head}>
            <div className={styles.title}>
              Wear It With
            </div>
          </div>
          <div className={styles.qrecs_prods}>
            {outf_products.map((item, index) => 
              <button 
                type="button"
                key={`outf_${item.handle}`} 
                className={[aria_btn, styles.qrec].join(' ')}
                onClick={(e)=>{handleLink(item, index)}}
              >
                <div>
                  <img 
                    className={styles.img} 
                    src={pick_img(item)}
                    alt={`Buck Mason ${item.title}`} 
                    height="300" 
                    width="200" 
                  />
                </div>
                <div>
                  <div className={styles.title_price}>
                    <div className={styles.title}>
                      {notBlank(item.pl_name) ? item.pl_name : item.title}
                    </div>
                    <div className={styles.price}>
                      ${item.first_product_price}
                    </div>
                  </div>
                  {notBlank(item.clr) && (
                    <div className={styles.color}>
                      {item.clr}
                    </div>
                  )}
                </div>
              </button>
            )}
          </div>
        </>
      )}
      {(rec_products && rec_products?.length > 0 ) && (
        <>
          <div className={styles.qrecs_head}>
            <div className={styles.title}>
              You Might Also Like
            </div>
          </div>
          <div className={styles.qrecs_prods}>
            {rec_products.map((item, index) => 
              <button 
                type="button"
                key={`rec_${item.handle}`} 
                className={[aria_btn, styles.qrec].join(' ')}
                onClick={(e)=>{handleLink(item, index)}}
              >
                <div>
                  <img 
                    className={styles.img} 
                    src={pick_img(item)}
                    alt={`Buck Mason ${item.title}`} 
                    height="300" 
                    width="200" 
                  />
                </div>
                <div>
                  <div className={styles.title_price}>
                    <div className={styles.title}>
                      {notBlank(item.pl_name) ? item.pl_name : item.title.replaceAll('-',' ')}
                    </div>
                    <div className={styles.price}>
                      ${item.first_product_price}
                    </div>
                  </div>
                  {notBlank(item.color) && (
                    <div className={styles.color}>
                      {item.color}
                    </div>
                  )}
                </div>
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default QuickRecs
