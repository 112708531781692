import { useEffect } from 'react'
import { useTheme } from '~/contexts/ThemeContext'
import PdpInit from '~/initializers/pdp-init'
import useInventoryHook from '~/utils/use-inventory'

const usePdpInvHook = ({
  product, variants, setVariants, prodInv, setProdInv, 
  showCta, setShowCta, showBis, setShowBis, showAfterp, setShowAfterp, 
  isLow, setIsLow
}) => {
  const { locate, pdpInv, pdpVariant, isSHM, shiplog } = useTheme()
  const { getinv } = useInventoryHook()
  const { setupVariants, checkCta, checkLow } = PdpInit()


  // Inventory ----------------------------------------------------------------

  const recheckCta = () => {
    const new_cta = checkCta({variants, pdpVariant})
    if (new_cta !== showCta) setShowCta(new_cta)
    if ((!new_cta) !== showBis) setShowBis(!new_cta)
    if (new_cta !== showAfterp) setShowAfterp(new_cta)
  }

  const recheckLow = (new_variants) => {
    const new_low = checkLow({variants: variants, pdpVariant, isGift: product.is_gift})
    if (isLow !== new_low) setIsLow(new_low)
  }


  const handlePdpInv = () => {
    if (prodInv) return

    const pinv = (pdpInv && pdpInv?.find(x => x.id === product.shop_id)) || false
    if (!pinv) return
    const new_variants = setupVariants({variants, isGift: product.is_gift, prodInv: pinv, isSHM, locate})
    pinv.variants = new_variants
    setVariants(new_variants)
    setProdInv(pinv)

    if (shiplog) {
      console.log(pinv)
    }

    if (sessionStorage.getItem('cypress_test')) {
      window.variants = pinv.variants
    }
  }

  // Subcribers ---------------------------------------------------------------

  useEffect(() => {
    if (!variants) return
    if (product.is_gift || product.csoon || !prodInv) return

    recheckCta()
    recheckLow()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdpVariant])

  useEffect(() => {
    handlePdpInv()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdpInv])

  const initInv = () => {
    getinv(product.pl_pima, product.pl_colors) 
  }

  return { initInv, recheckCta }
}


export default usePdpInvHook




// last user size select
// if (product.product_line?.sztyp && localStorage.getItem('sztyp')) {
//   let size_type = JSON.parse(localStorage.getItem('sztyp'))
//   size_type = size_type[product.product_line?.sztyp]
//   const has_size = variants.find(x=> x.title === size_type)
//   if (has_size) last_sztyp = has_size
// }



