import Geoloc from '~/utils/geoloc'

const usePdpStoresHook = ({
  product, setNearInstock, setCurrStore, setFarInstock, setAnyStores, setIsPickup, setAvailStoreName,
  onSwitchDelivery
}) => {
  const { getDistances } = Geoloc()

  const checkPickupOpts = ({pdpVariant, isPickup, blockPickup, shipPref, isSHM}) => {
    if (!pdpVariant?.inv) return
    if (blockPickup) onSwitchDelivery('ship', false)

    let currship = !isPickup ? 'ship' : 'pickup'
    const wh_combo = pdpVariant && (!!pdpVariant.inv.DW || !!pdpVariant.inv['BL - Ecommerce'])
    const ship_avail = !isSHM ? !!pdpVariant.ship : wh_combo

    if (pdpVariant.available && ship_avail && pdpVariant.pickup) {
      if (shipPref !== currship) onSwitchDelivery(shipPref, false)
      return
    }

    let newship = currship
    if (pdpVariant.available && !pdpVariant.pickup && ship_avail) newship = 'ship'
    if (pdpVariant.available && !ship_avail && pdpVariant.pickup) newship = 'pickup'
    if (newship !== currship) onSwitchDelivery(newship, false)
  }

  const checkStoreDists = ({storesData, pdpVariant, locate}) => {
    const storesobj = storesNear({storesData, pdpVariant, locate})
    setNearInstock(storesobj.nears)
    setCurrStore(storesobj.store)
    setFarInstock(storesobj.others)

    if (locate?.currStores?.length > 0) {
      const currStore = storesData.flatMap(x => x.stores)
                    .filter(x => !!x.pickup_enabled)
                    .find(x => x.short_name === locate.currStores[0])

      let gend_check = false
      if (locate?.currStores?.length > 0 && product.gender !== 'u' && locate?.storeGend) {
        // const loc_gend = locate.storeGend.toLowerCase().includes('women') ? 'w' : 'm'
        if (product.gender === locate.storeGend) gend_check = true
      }
      if (currStore && gend_check) {
        setIsPickup(true)
        setAvailStoreName(currStore.pima_title)
      }
    }
  }

  const checkAnyStores = ({prodInv, pdpVariant, anyStores}) => {
    if (!prodInv?.inventory) {
      if (anyStores?.length > 0) setAnyStores([])
      return
    }

    const whs = ['WH','DW','BL - Ecommerce']

    const to_check = !pdpVariant ? prodInv.inventory : pdpVariant.inv
    if (!to_check) {
      if (anyStores?.length > 0) setAnyStores([])
      return
    }

    let short_names = Object.keys(to_check).filter(x => !whs.includes(x))

    short_names = short_names.filter(x => 
      !pdpVariant ? to_check[x].length > 0 : !!to_check[x]
    )
    setAnyStores(short_names)
  }

  const redistPdpStore = (stores, store) => {
    if (!store?.coordinates) return stores
    const newdists = getDistances([{stores: stores}], false, store.coordinates)
    return newdists.flatMap(x => x.stores)
  }

  const storesNear = ({storesData, pdpVariant, locate}) => {
    const gender = product.gender

    let stores = storesData.flatMap(x => x.stores)
    stores = stores.filter(x => !!x.pickup_enabled)

    if (gender !== 'u') {
      stores = stores.filter(x => 
        gender === 'm' ? !!x.avail_mens : !!x.avail_womens
      )
    }

    let store = null

    let off_gend = false
    if (locate.currStores.length > 0 && gender !== 'u' && locate?.storeGend) {
      off_gend = gender !== locate.storeGend
    }

    if (!!locate.pdpStore || (locate.currStores.length > 0 && !off_gend)) {
      let usr_store = !!locate.pdpStore ? locate.pdpStore : locate.currStores[0]
      store = stores.find(x => x.short_name === usr_store)
      stores = redistPdpStore(stores, store)
    }

    let nears = stores.filter(x => x.distance <= 100)
    let near_shorts = nears.map(x => x.short_name)
    
    if (pdpVariant?.inv) nears = nears.filter(store => !!pdpVariant.inv[store.short_name])

    const any_found = nears.length > 0
    const new_store = store ? store : any_found ? nears[0] : false

    let others = storesData.map(x => structuredClone(x))
    others.forEach(reg => reg.stores = reg.stores.filter(x => {
      let strgend = true 
      if (gender !== 'u') strgend = gender === 'm' ? !!x.avail_mens : !!x.avail_womens
      const stock = pdpVariant.inv ? !!pdpVariant.inv[x.short_name] : true
      return stock && strgend && !near_shorts.includes(x.short_name)
    }))
    others = others.filter(x => x.stores.length > 0)

    return {
      nears: nears,
      store: new_store,
      others: others
    }
  }

  return { 
    checkPickupOpts, checkStoreDists, checkAnyStores
  }
}


export default usePdpStoresHook

