import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { useTheme }  from '~/contexts/ThemeContext'
import { useCart } from '~/contexts/CartContext'
import PdpInit from '~/initializers/pdp-init'
import usePdpInvHook from '~/hooks/usePdpInvHook'
import usePdpStoresHook from '~/hooks/usePdpStoresHook'
import usePdpOtherDataHook from '~/hooks/usePdpOtherDataHook'
import useAnalytics from '~/utils/analytics'
import useLastViewsHook from '~/utils/last-views'

const PdpContext = createContext()

export function PdpProvider({ children, productData, variantData, isQuick=false }) {
  const { 
    setFreeze, setQuick, setSearch, setShowSizing, pdpVariant, pdpInv, locate, setLocate, storesData, 
    setPdpVariant, shipPref, setShipPref, isSHM, gender, setGender
  } = useTheme()
  const { setCartOpen, setShowGiftCard } = useCart()
  const { setupVariants, checkCta, checkShipPick, checkLow } = PdpInit()
  const { ana_pdp } = useAnalytics()
  const { setLasts } = useLastViewsHook()

  const { is_gift, csoon, final_sale, outfits, outfills } = productData

  const initStates = useMemo(() => {
    let icta = is_gift ? true : checkCta({variants: variantData})
    if (csoon) icta = false

    const iship = is_gift ? {ship: true, pickup: false} : checkShipPick({variants: variantData})

    const is_test = false; //(typeof sessionStorage !== 'undefined') && sessionStorage.getItem('outftest')
    // if (!is_test && product.gender === 'u') return
    const ioutfs = (outfits && outfits.length > 0) || (is_test && outfills && outfills.length > 0)

    const ilow = checkLow({variants: variantData, pdpVariant, isGift: is_gift})

    let iinv = null
    let ivars = null
    if (isQuick) {
      const pinv = (pdpInv && pdpInv?.find(x => x.id === productData.shop_id)) || false
      // ({variants, isGift, prodInv={}, isSHM=false, locate=buildLocate}) => {
      if (pinv) {
        const opts = {
          variants: variantData, 
          isGift: productData.is_gift,
          prodInv: pinv,
          isSHM: isSHM,
        }
        if (locate) opts.locate = locate
        ivars = setupVariants(opts)
        iinv = pinv
      }
    }

    return {
      icta,
      iship,
      ioutfs,
      iinv,
      ivars,
      ilow
    }
  }, [productData, variantData])

  const product = useMemo(() => productData, [productData])
  const [didLoad, setDidLoad] = useState(false)
  const [variants, setVariants] = useState(initStates.ivars || variantData)
  const [recProds, setRecProds] = useState(productData?.recommends || [])
  const [shipCheck, setShipCheck] = useState(initStates.iship)
  const [isLow, setIsLow] = useState(initStates.ilow)
  const [showCta, setShowCta] = useState(initStates.icta)
  const [showBis, setShowBis] = useState(!initStates.icta)
  const [showAfterp, setShowAfterp] = useState(initStates.icta)
  const [showShipInfo, setShowShipInfo] = useState(initStates.icta && final_sale)
  const [showOutfits, setShowOutfits] = useState(initStates.ioutfs)
  const [showStickyCta, setShowStickyCta] = useState(false)

  const [prodInv, setProdInv] = useState(initStates.iinv)
  const [attributes, setAttributes] = useState({})
  const [showHemInfo, setShowHemInfo] = useState(false)
  const [blockPickup, setBlockPickup] = useState(false)
  const [wasRedirect, setWasRedirect] = useState(false)
  const [checkVarParam, setCheckVarParam] = useState(false)

  const [availStoreName, setAvailStoreName] = useState(null)
  const [isPickup, setIsPickup] = useState(false)
  const [showIntlBtn, setShowIntlBtn] = useState(false)
  const [nearInstock, setNearInstock] = useState([])
  const [farInstock, setFarInstock] = useState([])
  const [currStore, setCurrStore] = useState(null)
  const [anyStores, setAnyStores] = useState([])
  const [showStoresPanel, setShowStoresPanel] = useState(false)
  const [reviews, setReviews] = useState([])


  const pdpInvHook = usePdpInvHook({
    product, variants, setVariants, prodInv, setProdInv, showCta, setShowCta, 
    showBis, setShowBis, showAfterp, setShowAfterp, isLow, setIsLow
  })
  const { initInv, recheckCta } = pdpInvHook

  const otherDataHook = usePdpOtherDataHook({ product, setRecProds, setReviews })
  const { getReviews, getPdpRecs } = otherDataHook
  // getRecsJson, 


  const onSwitchDelivery = (type, isUser=true) => {
    setIsPickup(type === 'pickup')
    if (isUser) setShipPref(type)
    if (type === 'ship') {
      onSetInstore(null)
    }
  }

  const pdpStoresHook = usePdpStoresHook({
    product, setNearInstock, setCurrStore, setFarInstock, setAnyStores, setIsPickup, setAvailStoreName,
    onSwitchDelivery
  })
  const { checkStoreDists, checkPickupOpts, checkAnyStores } = pdpStoresHook

  const onSetInstore = (store_name) => {
    setAvailStoreName(!isPickup ? null : store_name)
  }

  const onVariantSelect = (id, vars_arr=false) => {
    const var_source = vars_arr || variants
    const new_size = var_source.find( x => x.id === parseInt(id) )
    if (!new_size) return

    new_size.prod_shop_id = product.shop_id
    new_size.gender = product.gender

    if (!isQuick) window.history.replaceState({}, null, `/products/${product.handle}?variant=${id}`)
    setPdpVariant(new_size)

    if (product.product_line?.sztyp) {
      const size_type = localStorage.getItem('sztyp') ? JSON.parse(localStorage.getItem('sztyp')) : {}
      if (size_type[product.product_line?.sztyp] !== new_size.title) {
        size_type[product.product_line?.sztyp] = new_size.title
        localStorage.setItem('sztyp', JSON.stringify(size_type))
      }
    }
  }

  const onSelectHem = (attr, hem_data) => {
    if ( attr !== false ) {
      setAttributes({attributes: [{key: hem_data.code, value: attr.toString()}]})
      setBlockPickup(true)
      setAvailStoreName(null)
      return
    }
    setBlockPickup(false)
  }

  const setIntl = (state) => {
    setLocate({
      ...locate,
      shipIntl: state
    })
  }

  const checkLastVariant = (var_arr) => {
    // removed auto selecting last user selected size from different pline
    // see comment at bottom
    let last_sztyp = false
    let curr_size = false

    // check last pdp size for preference if present
    if (pdpVariant) {
      curr_size = var_arr.find(x=> x.title === pdpVariant.title)
      if (curr_size) {
        onVariantSelect(curr_size.id, var_arr)
        return
      }
    }
    if (!product.is_gift && !pdpVariant && last_sztyp) {
      const wh_combo = (!!last_sztyp?.inv.DW || !!last_sztyp?.inv['BL - Ecommerce'])
      const ship_avail = !isSHM ? !!last_sztyp.ship : wh_combo
      if (last_sztyp?.available && (ship_avail || last_sztyp?.pickup)) {
        onVariantSelect(last_sztyp.id, var_arr)
        return
      }
    }

    const checkOneSize = (title) => {
      const is_os = title.toLowerCase() === 'one size' || title.toLowerCase() === 'os'
      return is_os
    }

    // if product is one size, size that size
    if (!pdpVariant && var_arr.length === 1 && checkOneSize(var_arr[0].title)) {
      const curr_size = var_arr[0]
      onVariantSelect(curr_size.id, var_arr)
    }
  }

  const checkParams = () => {
    // after pageload and inv is set, check for a variant param to
    // set selected variant if direct link, or if PDP has same sizes
    // as last select same either from saved preference or last pdp

    const url = new URL(window.location.href);
    const variantId = url.searchParams.get('variant')
    if (variantId) onVariantSelect(variantId)
    if (!variantId) checkLastVariant(variants)

    setCheckVarParam(true)
  }


  const initActions = () => {
    if (!isQuick) setQuick(false)
    setFreeze(false)
    setSearch(false)
    setCartOpen(false)
    setShowGiftCard(false)
    setShowSizing(false)

    initInv()
    setDidLoad(true)
    setLasts(product)
    // if (!recProds) getRecsJson()
    if (isQuick) getPdpRecs()
    if (productData?.product_line?.has_reviews) getReviews()

    const new_gend = product.gender === 'w' ? 'women' : 'men'
    if (gender !== new_gend) setGender(new_gend)

    if (sessionStorage.getItem('plRedirect')) {
      setWasRedirect(true)
      sessionStorage.removeItem('plRedirect')
    }

    if (sessionStorage.getItem('cypress_test')) {
      window.product = productData
    }
  }

 
  useEffect(() => {
    if (!didLoad) initActions()
  }, [])

  useEffect(() => {
    if (!isQuick) return
    if (!didLoad) return
    setVariants(initStates.ivars || variantData)
    setRecProds([])
    setShipCheck(initStates.iship)
    setIsLow(initStates.ilow)
    setShowCta(initStates.icta)
    setShowBis(!initStates.icta)
    setShowAfterp(initStates.icta)
    setShowShipInfo(initStates.icta && final_sale)
    setShowOutfits(initStates.ioutfs)

    setProdInv(initStates.iinv)
    initActions()
    checkLastVariant(initStates.ivars || variantData)

  }, [productData])

  useEffect(() => {
    checkPickupOpts({pdpVariant, isPickup, blockPickup, shipPref, isSHM})
    checkAnyStores({prodInv, pdpVariant, anyStores})

    if (!checkVarParam) return
    // send analytics only after page load & variant param check
    // then on any variant change

    ana_pdp(product, pdpVariant)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdpVariant, checkVarParam])

  useEffect(() => {
    if (!locate) return
    checkStoreDists({storesData, pdpVariant, locate})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locate, pdpVariant])

  useEffect(() => {
    if (!variants || product.is_gift || product.csoon || !prodInv) return
    if (checkVarParam) return

    checkParams()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodInv])

  return (
    <PdpContext.Provider value={{
      product, prodInv, wasRedirect, showHemInfo, setShowHemInfo, blockPickup, recProds, shipCheck, availStoreName,
      isPickup, isLow, showCta, showBis, showAfterp, showOutfits, showShipInfo, onVariantSelect, 
      onSelectHem, attributes, showIntlBtn, setIntl, onSetInstore, onSwitchDelivery, isQuick, setIsPickup,
      anyStores, variants, nearInstock, farInstock, currStore, setCurrStore, showStoresPanel, setShowStoresPanel,
      showStickyCta, setShowStickyCta, setAvailStoreName, reviews
    }}>
      {children}
    </PdpContext.Provider>
  );
}

export function usePdp() {
  const context = useContext(PdpContext)
  if (context === undefined) {
    throw new Error('usePdp must be used within an PdpProvider')
  }
  return context
}

