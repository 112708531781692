export function jnstyls(styles) {
  return styles.filter(x => !!x && x !== '').join(' ')
}

export function checkMobile() {
  if ((typeof document === "undefined") || (typeof window === "undefined")) return false

  const test_touch = sessionStorage.getItem('force-touch')
  const touches = test_touch || 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
  const mobileagent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const mobile_size = window.innerWidth < 820
  const tablet_size = window.innerWidth > 819

  if (touches) document.querySelector('html').classList.add('touchevents')

  return {touches: touches, mobile: mobile_size, tablet: touches && tablet_size, mobileagent: mobileagent}
}

export function inViewport(el) {
  const rect = el.getBoundingClientRect()
  return (
    Math.round(rect.top + rect.height) >= 0 &&
    Math.round(rect.left) >= 0 &&
    Math.round(rect.bottom) <= (window.innerHeight || document.documentElement.clientHeight) &&
    Math.round(rect.right) <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function inViewPartial(el) {
  const rect = el.getBoundingClientRect()
  const w_height = (window.innerHeight || document.documentElement.clientHeight)
  const w_width = (window.innerWidth || document.documentElement.clientWidth)
  const top = Math.round(rect.top) >= 0 && Math.round(rect.top) <= w_height
  const bottom = Math.round(rect.bottom) >= 0 && Math.round(rect.bottom) <= w_height
  const left = Math.round(rect.left) >= 0
  const right = Math.round(rect.right) <= w_width

  return (top || bottom) && (left || right)
}

export function inViewPartLarge(el) {
  if (!el) return false
  const rect = el.getBoundingClientRect()
  const w_height = (window.innerHeight || document.documentElement.clientHeight)
  const w_width = (window.innerWidth || document.documentElement.clientWidth)
  const vert = Math.round(rect.top) < w_height && Math.round(rect.bottom) > 0 
  const horiz = Math.round(rect.left) < w_width && Math.round(rect.right) > 0
  
  return vert && horiz
}

export function altText(alt, title) {
  if (!alt || alt.trim() === '') {
    return `View of the ${title}`
  }
  if (alt.toLowerCase().includes('product flat')) {
    return `${title} - ${alt}`
  }
  return alt
}

export function isStag() {
  return process.env.GATSBY_CURR_ENV === 'development'
}

export function notBlank( data ) {
  return data && data !== ''
}

export function imgCDN( url ) {
  const cdnf = isStag() ? 'https://cdn.shopify.com/s/files/1/0127/7535/8521/files' : 'https://cdn.shopify.com/s/files/1/0123/5065/2473/files'
  const cdnp = isStag() ? 'https://cdn.shopify.com/s/files/1/0127/7535/8521/products' : 'https://cdn.shopify.com/s/files/1/0123/5065/2473/products'

  // if (url.includes('cdnf:/')) url = url.replace('cdnf:', cdnf)
  // if (url.includes('cdnp:/')) url = url.replace('cdnp:', cdnp)

  if (url && url.includes('cdnf:')) url = url.replace('cdnf:', cdnf)
  if (url && url.includes('cdnp:')) url = url.replace('cdnp:', cdnp)

  return url
}

export function reShopId(id) {
  return id.includes('gid://shopify/Product/') ? id : `gid://shopify/Product/${id}`
}

export function deShopId(id) {
  return id?.replace('gid://shopify/Product/','')
}

export function imgSize( url, size, nowebp=false ) {
  if (!url) return ''
  let new_url = url
  if (url.includes('cdnf:') || url.includes('cdnp:')) new_url = imgCDN(new_url)

  if (!new_url) return ''

  if (new_url.slice(0,2) === '//') new_url = `https:${new_url}`
  
  new_url = new URL(new_url)
  if (!nowebp) new_url.searchParams.append('format','webp')
  new_url.searchParams.append('width',size)

  // return `${new_url}${new_url}${!nowebp ? 'format=webp' : ''}&width=${size}`
  return new_url.toString()
}

export function oneSize(title) {
  const is_os = title.toLowerCase() === 'one size' || title.toLowerCase() === 'os'
  return is_os ? 'One Size' : title
}

export function stripHTML( data ) {
  return data.replace(/<[^>]*>?/gm, '')
} 

export function scrolldown( el ) { 
  const head_el = document.querySelector('#header')
  if (!head_el) return
    
  const header = head_el.offsetHeight + 20
  window.scrollTo({
    top: document.querySelector(el).offsetTop - header,
    behavior: "smooth"
  });
}

export function secColorBg( hex_color ) { 
  return `linear-gradient(45deg, transparent 33.33%, ${hex_color} 33.33%, ${hex_color} 50%, transparent 50%, transparent 83.33%, ${hex_color} 83.33%, ${hex_color} 100%)`
}

export function hashCode( str ) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return hash.toString(16);
}

export function setCookie(name, value, minutes=null, hours=null, days=null) {
  if (typeof document === 'undefined') return
  const is_local = window.location.hostname.includes('localhost')
  let expires = ''
  let date = new Date()
  const days_time = days ? days * 24 * 60 * 60 * 1000 : hours ? hours * 60 * 60 * 1000 : minutes ? minutes * 60 * 1000 : 0
  date.setTime(date.getTime() + days_time)
  expires = `; expires=${date.toUTCString()}`
  document.cookie = `${name}=${(value || '')}${expires}; path=/;${is_local ? '' : ' domain=buckmason.com'}; SameSite=Lax;`
}

export function getCookie(name) {
  if (typeof document === 'undefined') return null
  const nameEQ = `${name}=`
  var ca = document.cookie.split(';')
  for(var i=0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1,c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length)
  }
  return null
}

export function compact(arr) {
  return arr.filter(x => x !== null && x !== undefined && x !== '')
}



